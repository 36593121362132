import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import LangSwitcher from "../translation/languageSwitcher"
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from '../../actions/language';
import { HomePage } from '../../translations/trans';
import { Log_Out } from '../../actions/login&register';
function MainMenu({ lang, main, user }) {
  const dispatch = useDispatch();

  const { laang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    // تحقق إذا كان العنصر هو تسجيل الخروج أو تسجيل الدخول
    if (item.name === 'تسجيل الخروج') {
      // قم بإعادة تحميل الصفحة
      dispatch(Log_Out());
      navigate("/login")
      // window.location.reload();
    }
  };
  return (
    <>
      <nav className="main-menu">
        <ul>
          <h5 id='mainm1'>{HomePage.home[laang]}</h5>
          {main && main.map((item, index) =>
            <>
              <li key={index}>
                <NavLink
                  id={'mainmenu' + index}
                  to={item.to}
                  className="main-menu-item"
                  style={({ isActive }) => ({
                    color: '#ffffff',
                    opacity: isActive ? 1 : "",
                    borderBottom: '1px solid',
                    marginBottom: '10px'
                  })}
                  onClick={() => handleItemClick(item)}
                >
                  {item.name}
                </NavLink>
              </li>
            </>

          )}
        </ul>
        <div className="mx-3" style={{ width: "150px" }}>
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => {
              dispatch(setLang(e.target.value));
            }}
            value={laang}
          >
            <option value="AR">AR</option>
            <option value="EN">EN</option>
          </select>
        </div>
      </nav>
    </>
  )
}

export default MainMenu
