import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { getLevelById } from '../../../utils/API';
import { getMaterialByCode } from '../../../utils/materials';
import { getLessonsByIDS } from '../../../utils/lessons';
import { LuView } from "react-icons/lu";

function ViewPackage({ pack }) {
  const [level, setLevel] = useState(null);
  const [material, setMaterial] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handlePackage = async () => {
    try {
      const levelResponse = await getLevelById({_id:pack?.levelId});
      setLevel(levelResponse.data?.level);

      const materialResponse = await getMaterialByCode({code:pack?.materialCode});
      setMaterial(materialResponse.data?.material);

      const lessonsResponse = await getLessonsByIDS({ lessonsIDS: pack?.selectedLessons });
      setLessons(lessonsResponse.data?.lessons);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    handlePackage();
  }, []);

  const handleViewButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <LuView variant="primary" color='gray' size={30} onClick={handleViewButtonClick}>
        
      </LuView>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Package Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {level&&material&&lessons?.length?<>
          <p>المستوى: {level&&level?.name}</p>
          <p>المادة: {material?.name}</p>
          <p>الدروس:</p>
          <ul>
            {lessons.map((lesson) => (
              <li key={lesson?._id}>{lesson?.name}</li>
            ))}
          </ul>
          
          </>:<div>Loading.......</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewPackage;
