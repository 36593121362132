import React, { useEffect, useState } from 'react'
import { getLessonById } from '../../utils/lessons'

const GetLessonId = ({lessonId}) => {
    const [lessonName, setLessonName] = useState([]);
    const getLesson = async () => {
        try {
            const res = await getLessonById({
                _id: lessonId,
            })
            setLessonName(res?.data?.lesson?.name)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getLesson();
    }, [lessonId])
    return (
        <div>
            <span>{lessonName}</span>
        </div>
    )
}

export default GetLessonId
