import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addTestException } from '../../../utils/tests';
import { success } from '../../../utils/Toast';
const ModalAddException = ({ userId, name, testId }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [exception, setException] = useState(null)


    const handleTestException = async () => {
        try {
            const res = await addTestException({
                studentID: userId,
                testID: testId,
                exception: exception
            })
            if (res?.data?.case) {   
                success(`تمت الاضافه`)
                handleClose();      
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div>
            <Button variant="primary" onClick={handleShow}>
                اضافة محاولات
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <h4>اضف محاولات اخري للطالب {name}</h4>
                    <div className='mt-3'>
                        <input type="number" name="testException" id="testException" className='form-control' value={exception} onChange={(e) => setException(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        الغاء
                    </Button>
                    <Button variant="primary" onClick={handleTestException}>
                        اضافه
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAddException
