import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Inetialize_Levels } from '../../../actions/curriculums/levels';
import { BoxLoading, WaveLoading, SolarSystemLoading } from 'react-loadingg';
import { GrView } from 'react-icons/gr';
import { Container, Table, Button, Modal, Row, Col } from 'react-bootstrap';
import ModalEdit from './ModalEdit';
import ModalDelete from './ModalDelete';
import ModalAddMaterial from './ModalAddMaterial';
import ExpandedContent from './ExpandedContent';
import Level from './level';
import { HomePage } from '../../../translations/trans';

const Alllevels = () => {
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState('');
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [selectedLevelId, setSelectedLevelId] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoad(true);
    dispatch(Inetialize_Levels())
      .then((res) => {
        setLoad(false);
        if (!res.data.case) {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.warn(err);
        setMessage(err.message);
      });
  }, [message]);
  // 
  const levelsContainer = useSelector((state) => state.levels);
  //  
  const { materials } = useSelector(state => {
    return { materials: state.materials }
  })

  const handleShowAddMaterialModal = (levelId) => {
    setSelectedLevelId(levelId);
    setShowAddMaterialModal(true);
  };
  //   
  const handleCloseAddMaterialModal = () => {
    setSelectedLevelId(null);
    setShowAddMaterialModal(false);
  };

  const toggleRow = (levelId) => {
    setExpandedRows((prevRows) =>
      prevRows.includes(levelId) ? prevRows.filter((row) => row !== levelId) : [...prevRows, levelId]
    );
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <Container className="">
      <span style={{ color: 'red' }}>{message}</span>

      <Table responsive bordered hover>
        <thead>
          <tr>
            <th>{HomePage.nameForm[lang]}</th>
            <th>{HomePage.AddMaterial[lang]}</th>
            <th>{HomePage.action[lang]}</th>
          </tr>
        </thead>
        <tbody>
          {levelsContainer.length ? (
            levelsContainer.map((item, i) => (
              <Level key={i}
                item={item}
                materials={materials}

              />
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                <h2>{HomePage.noLevels[lang]}</h2>
                <WaveLoading />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </Container>
  );
};

export default Alllevels;
