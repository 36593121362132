import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { urlBase } from '../../../utils/API';
import { Get_All_Packages, Update_Package } from '../../../actions/packages';
import { success } from '../../../utils/Toast';
import axios from "axios";

const EditDetails = ({ pack }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [namePackage, setPackageName] = useState(pack?.name);
    const [coverImage, setCoverImage] = useState(pack?.coverImage);

    const dispatch = useDispatch();

    const handlePackage = async () => {
        try {

            const res = await dispatch(Update_Package({ ...pack, name: namePackage, coverImage, id: pack._id }));
            success(res?.data?.message);
            handleClose();
        } catch (e) {
            console.log(e);
            dispatch(Get_All_Packages())
            handleClose();
        }
    };

    return (
        <div>
            <Button style={{ backgroundColor: '#fff', color: 'gray' }} onClick={handleShow}>تعديل التفاصيل</Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>تعديل البرنامج</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>اسم البرنامج</label>
                        <input
                            type="text"
                            name="namePackage"
                            className="form-control w-100"
                            id="namePackage"
                            value={namePackage}
                            onChange={(e) => setPackageName(e.target.value)}
                        />
                    </div>

                    

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        اغلاق
                    </Button>
                    <Button variant="primary" onClick={handlePackage}>
                        حفظ التعديلات
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EditDetails;
