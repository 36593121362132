import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getLessonById } from '../../../utils/lessons';
import AddTest from '../tests/addTest';

const ShowLessonTest = () => {
    // Get Lesson id From Params and get Test
    const params = useParams();
    const [lessonTest, setLessonTest] = useState(null);
    const getLesson = async () => {
        if (!params?.id) return;
        try {
            const resLesson = await getLessonById({ _id: params.id })
            console.log(resLesson)
            if(resLesson?.data?.case){
                setLessonTest(resLesson?.data?.lesson?.testIDS)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getLesson()
    }, [params?.id])
    return (
        <div>
            هنا يوجد عرض الامتحانات الخاصه بالدروس

            {
                lessonTest?.map((lesson)=>{
                    return(
                        <AddTest lesson={lesson}/>
                    )
                })
            }
        </div>
    )
}

export default ShowLessonTest
