import React, { useState, useRef } from 'react'
import "./newAccount.css"
import Modal from "../../modal/modal.js";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import { FcPlus } from "react-icons/fc";
import Input from "../../input/input";
import serializeForm from "form-serialize";
import { useDispatch, useSelector } from "react-redux";
import {
  Register_Admin,
  Register_Teacher,
  Register_Student,
} from "../../../actions/login&register";
import { ErrorMsg } from '../../../utils/Toast.js';
import { HomePage } from '../../../translations/trans.js';
function NewAccount({ type }) {
  const [open, setOpen] = useState(false);
  const form = useRef();
  const dispatch = useDispatch();
  //-----------Create New Account Function------
  const createNew = async (e) => {
    e.preventDefault();
    try {
      const data = serializeForm(form.current, { hash: true });
      data.role = type;
      const today = new Date()
      data.date = today.toISOString();
      if (type === "teacher") {
        await dispatch(Register_Teacher(data));

      }
      else if (type === "student") {
        const res = await dispatch(Register_Student(data));
        console.log(res)
      }
      else if (type === "admin") {
        await dispatch(Register_Admin(data));
      }
      setOpen(false)
    } catch (e) {
      console.log(e)
      if (e?.response) {
        ErrorMsg(e?.response?.data?.message)
      }
    }
  }

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  return (
    <>
      <div className='fcplus-cont'>
        <FcPlus
          size="2em"
          onClick={() => setOpen(true)}
          style={{
            position: "absolute",
            // top: "10px",
            right: "5px",
            cursor: "pointer",
          }}
        />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        width={"70vw"}
        height="60vh"
      >
        <form ref={form} onSubmit={createNew}>
          <Contain>
            <Col dir="column" fg="1" border="0px">
              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="100%"
              >
                <Input
                  name="name"
                  type="text"
                  placeholder="Full name"
                  label="Full name"
                />
                <Input
                  name="username"
                  type="text"
                  placeholder="Username"
                  label="Username"
                />
                <Input
                  name="parentPhone"
                  type="text"
                  placeholder="parent phone"
                  label="parent phone"
                />
              </Col>
              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="100%"
              >
                <Input name="email" type="email" placeholder="Email" />
                <Input
                  name="password"
                  type="password"
                  placeholder="Password"
                />
                <Input name="phone" type="text" placeholder="Phone" />
              </Col>
              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="100%"
              >

                <Input type='text' name='country' id='country' placeholder="اسم الدوله" />
                <Input type='text' name='schoolName' id='schoolName' placeholder="اسم المدرسه" />
                <select className="w-25 form-control" name="grade" id="grade" style={{ borderRadius: "20px" }} >
                  <option hidden>اختر الصف</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </select>
              </Col>

              <Col
                fg="1"
                justify="space-around"
                border="0px"
                perfix="form-row-n"
                width="100%"
              >
              </Col>
              <input type="submit" name="Save" placeholder="Save" className='subm' />
            </Col>
          </Contain>
        </form>
      </Modal>
    </>
  )
}

export default NewAccount