import React, { useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaCirclePlus } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux"

import { Create_Levele } from '../../../actions/curriculums/levels';
import { success } from '../../../utils/Toast';
import { HomePage } from '../../../translations/trans';
const AddLevel = () => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [load, setLoad] = useState(false);
    const [selectSystem, setSelectSystem] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();
    const onSubmit = async () => {
        setLoad(false)
        const res = await dispatch(Create_Levele({
            name,
            branch: selectSystem
        }))
        if (res?.data?.case) {
            success(res?.data?.message)
        }
        setLoad(true)
        handleClose()
    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (

        <div className='mainHome'>
            <button onClick={handleShow} className='addLevelIcon'>
                <FaCirclePlus size='34' color='green' />
                <span>{HomePage.addLevel[lang]}</span>
            </button>

            {/* Modal Has Form  */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{HomePage.addLevelName[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <label>{HomePage.levelName[lang]} </label>
                        <input className='form-control mt-2' type="text" id="levelname" value={name} onChange={(e) => setName(e.target.value)} />
                        <div className='mt-3'>
                            <label>{HomePage.chooseLevelsystem[lang]}</label>
                            <select className='form-control mt-2 w-100' onChange={(e) => setSelectSystem(e.target.value)} value={selectSystem}>
                                <option hidden>select system</option>
                                <option value="AC"> Cambridge</option>
                                <option value="AE"> Edexcel</option>
                                
                            </select>
                        </div>
                    </form>
                    <button className='btn mt-2 bg-primary text-light' onClick={onSubmit}>
                        Submit
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddLevel
