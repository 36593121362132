// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
// import { getPackageById } from '../../../utils/packages';
// import BlueHeader from '../blueHeader';
// import image from "../../../assets/images/loginImage.png"

// const DetailsPackage = () => {

//     const params = useParams();

//     const [packageData, setPackageData] = useState([]);


//     const getPackageId = async () => {
//         try {
//             const res = await getPackageById({
//                 id: params?.id
//             })
//             if (res?.data?.case) {
//                 setPackageData(res?.data?.package)
//             }
//         } catch (e) {
//             console.log(e)
//         }
//     }

//     const res = await getLevelById({ _id: levelId });

//     console.log(packageData)
//     useEffect(() => {
//         getPackageId()
//     }, [params?.id])
//     return (
//         <div>
//             <BlueHeader
//                 title={'البرامج الدراسيه'}
//                 subTitle={'البرامج الدراسيه'}
//                 secondSubTitle={"تفاصيل البرنامج"}
//             />
//             <div className="my-4" style={{ width: "90%", margin: "auto" }}>
//                 <div className="row">
//                     <div className="col-md-8">
//                         <h3 className="mb-3">{packageData?.name}</h3>
//                         <p>{packageData?.levelId}</p>

//                     </div>
//                     <div className="col-md-4  text-center">
//                         <img
//                             src={image}
//                             alt="Physics"
//                             className="w-50"
//                         />
//                         <div className="">
//                             <div className="text-muted">
//                                 <i className="fas fa-star"></i> (350 طالب)
//                             </div>
//                             <div className="font-weight-bold">السعر: {packageData?.price} جنيه</div>
//                         </div>
//                     </div>

//                 </div>
//             </div>

//             <div className="d-flex m-auto mb-5 w-50 text-center">
//                 <div className='m-auto'>
//                     <button className="btn mx-2" style={{ background: "#283476" }}>اشترك الآن</button>
//                     <button className="btn mx-2" style={{ backgroundColor: "#CC3030" }}>إلغاء</button>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default DetailsPackage


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPackageById } from '../../../utils/packages'; // Assuming getLevelById is imported here
import BlueHeader from '../blueHeader';
import image from "../../../assets/images/loginImage.png";
import { getLevelById, urlBase } from '../../../utils/API';
import { getMaterialByCode } from '../../../utils/materials';
import Subscripe from './subscripe';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../translations/trans';
const DetailsPackage = () => {
    const params = useParams();

    const [packageData, setPackageData] = useState({});
    const [levelData, setLevelData] = useState({});
    const [materialName, setMaterialName] = useState("");

    const { user } = useSelector(({ loggedUser }) => {
        return { user: loggedUser }
    })

    const getPackageId = async () => {
        try {
            const res = await getPackageById({
                id: params?.id
            });
            console.log(res)
            if (res?.data?.case) {
                const packageInfo = res?.data?.package;
                setPackageData(packageInfo);

                // Fetch level data based on levelId from package
                const levelId = packageInfo?.levelId;
                if (levelId) {
                    const levelRes = await getLevelById({ _id: levelId });
                    if (levelRes?.data?.case) {
                        setLevelData(levelRes?.data?.level);
                    }
                }

                const materialCode = res?.data?.package?.materialCode
                if (materialCode) {
                    const codes = await getMaterialByCode({ code: materialCode });
                    if (codes?.data?.case) {
                        setMaterialName(codes?.data?.material?.name)
                    }
                    console.log(codes)
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getPackageId();
    }, [params?.id]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <BlueHeader
                title={`${HomePage.studyProgram[lang]}`}
                subTitle={`${HomePage.studyProgram[lang]}`}
                secondSubTitle={`${HomePage.detailsProgram[lang]}`}
            />
            <div className="my-4" style={{ width: "90%", margin: "auto" }} dir={`${dir}`}>
                <div className="row">
                    <div className="col-md-8">
                        <h3 className="mb-3">{HomePage.material[lang]}:{materialName}</h3>
                        <p className="mb-3">{packageData?.name}</p>
                        <p>{levelData?.name}</p> {/* Displaying level name */}
                    </div>
                    <div className="col-md-4 text-center">
                        {
                            packageData?.coverImage !== null ? (
                                <img
                                    src={`${urlBase}/${packageData?.coverImage}`}
                                    alt="Physics"
                                    className="w-50"
                                />
                            ) : (
                                <img
                                    src={`${urlBase}/${packageData?.coverImage}`}
                                    alt="Physics"
                                    className="w-50"
                                />
                            )
                        }
                        <div className="">
                            <div className="text-muted">
                                {/* <i className="fas fa-star"></i> (350 طالب) */}
                            </div>
                            <div className="font-weight-bold">{HomePage.price[lang]}: {packageData?.price} جنيه</div>
                            <div className="font-weight-bold">{HomePage.discountPrice[lang]}: {packageData?.discountPrice} جنيه</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex m-auto mb-5 w-50 text-center">
                <div className='m-auto d-flex'>
                    {/* <button className="btn mx-2" style={{ background: "#283476" }}>اشترك الآن</button> */}
                    <Subscripe packData={packageData} user={user} />
                    <button className="btn mx-2" style={{ backgroundColor: "#CC3030" }}>{HomePage.cancel[lang]}</button>
                </div>
            </div>
        </div>
    );
};

export default DetailsPackage;
