import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { Link } from 'react-router-dom';
import { Set_Lesson } from '../../../actions/curriculums/view';
import { useDispatch, useSelector } from 'react-redux';
import AddTestBtn from '../tests/AddTestBtn';
import DeleteLesson from './deleteLesson';
import { HomePage } from '../../../translations/trans';
import ModalUpdateLesson from './ModalUpdateLesson';



const ViewLessons = ({ materialCode }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const href = window.location.pathname.split('/');
    href.pop()
    const URL = href.join('/')
    setUrl(URL)
  }, [])
  const dispatch = useDispatch();

  const handleOpenModal = async () => {
    try {
      setLoading(true);
      const lessonsData = await getLessonsOfSpecificMaterial({ code: materialCode });
      setLessons(lessonsData.data.lessons);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching lessons:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });


  return (
    <div>
      {/* Button to open the modal */}
      <Button variant="primary" onClick={handleOpenModal}>
        {HomePage.materialLesson[lang]}
      </Button>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size='lg' centered>
        <Modal.Header >
          <Modal.Title>{HomePage.materialLesson[lang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading lessons...</p>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{HomePage.nameLesson[lang]}</th>
                  <th>{HomePage.show[lang]}</th>
                  <th>{HomePage.showTest[lang]}</th>
                  {/* Add more table headers as needed */}
                  <th>{HomePage.test[lang]} </th>
                  <th>{HomePage.updateLesson[lang]} </th>
                  <th>{HomePage.delete[lang]}</th>
                </tr>
              </thead>
              <tbody>
                {lessons.length && lessons.map((lesson, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{lesson.name}</td>
                    <td><Link onClick={() => {
                      dispatch(Set_Lesson(lesson))
                    }} to={`${url}/view-lesson`}>{HomePage.show[lang]}</Link></td>
                    <td>
                      <Link to={`${url}/show-test-lesson/${lesson?._id}`}>{HomePage.showTest[lang]}</Link>
                    </td>

                    <td><AddTestBtn type={'lesson'} testFor={lesson._id} /></td>
                    <td>
                      <ModalUpdateLesson lessonId={lesson._id}  materialCode={materialCode}/>
                    </td>
                    <td> <DeleteLesson lessonId={lesson._id} updateLessons={handleOpenModal} /> </td>

                    {/* Add more table data cells as needed */}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewLessons;
