import React, { useState, useEffect } from 'react'
// import Header from './Header';
import './userDashboard.css';
import SideNav from '../Admin/sideNav.jsx';
import { Outlet, useParams } from 'react-router-dom'
import dashIcon from '../../assets/dashIcon.svg';
// import classManagIcon from '../../assets/classManag.svg';
import { TbMessages } from 'react-icons/tb';
// import {IoIosNotificationsOutline} from 'react-icons/io';
import { CgProfile } from 'react-icons/cg';
import { MdClass } from 'react-icons/md';
import MobileNave from './mobileNave'
import logOut from '../../assets/logout.svg';
import GetText from '../translation/getText.jsx'
import { useDispatch, useSelector } from 'react-redux';
import { Get_Materials } from '../../actions/curriculums/materials';
import { Inetialize_Levels } from '../../actions/curriculums/levels.js';
import Header from '../Home/header.jsx';
import { HomePage } from '../../translations/trans.js';

function UserDashboard({ user }) {
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  const params = useParams();
  // console.log(params  )
  const taps = [
    {
      name: `${HomePage.classes[lang]}`, path: "", icon: <MdClass />
    },
    { name: `${HomePage.message[lang]}`, path: 'messages', icon: <TbMessages /> },
    { name: `${HomePage.profile[lang]}`, path: 'profile', icon: <CgProfile /> },
  ]


  const adminTaps = [
    { name: `${HomePage.subscriptions[lang]}`, path: "" },
    { name: `${HomePage.levels[lang]}`, path: "new-level" },
    { name: `${HomePage.materials[lang]}`, path: "materials" },
    { name: `${HomePage.programs[lang]}`, path: "packages" },
    { name: `${HomePage.classes[lang]}`, path: "classes" },
    { name: `${HomePage.students[lang]}`, path: "students" },
    { name: `${HomePage.profile[lang]}`, path: "profile" },
    { name: `${HomePage.Newpass[lang]}`, path: "new-pass" },
    { name: `${HomePage.message[lang]}`, path: 'messages', icon: <TbMessages /> },


  ]

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.role === 'Admin') {
      dispatch(Get_Materials());
      dispatch(Inetialize_Levels());
    }
  }, [])
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className='user-dashboard' dir={`${dir}`}>
      <div className='user-dash-header'>
        <Header menuColor={'#333'} />
      </div>
      <div className='user-dash-body'>
        <div className='subhead public_bg'>
          <img src={dashIcon} alt='dashIcon' />
          <span id='suh1'>
            {HomePage.dashboard[lang]}
          </span>
        </div>
        <div className='controlmenu'>
          {windowSize.width > 900 ? <SideNav taps={user.role === 'Admin' ? adminTaps : taps} /> :
            <MobileNave taps={user.role === 'Admin' ? adminTaps : taps} />
          }            </div>
        <div id='sub-body'>
          <Outlet />
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>

        </div>
      </div>
    </div>

  )
}

export default UserDashboard
