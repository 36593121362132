import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaEye } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { MdDelete } from "react-icons/md";
import { GetMaterialsByIds } from '../../../utils/materials';
import { useDispatch } from 'react-redux';
import { Delete_Material } from '../../../actions/curriculums/materials';
import { Update_Level } from '../../../actions/curriculums/levels';

const ShowMateriaLevel = ({ item }) => {
    const [show, setShow] = useState(false);
    const [materialNames, setMaterialNames] = useState([]);
    const [remainingIds, setRemainingIds] = useState(item.materialIDS || []); // Track remaining material IDs
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        fetchMaterialNames();
    };

    // Fetch material names by IDs
    const fetchMaterialNames = async () => {
        try {
            const res = await GetMaterialsByIds({ IDs: remainingIds }); // Use the updated remaining IDs
            if (res?.data?.case) {
                setMaterialNames(res?.data?.materials);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Handle material deletion
    const handleDelete = (idMaterial) => {
        // Update UI: Remove the material by ID from the state
        const updatedMaterialNames = materialNames.filter(material => material._id !== idMaterial);
        const updatedRemainingIds = remainingIds.filter(id => id !== idMaterial);

        setMaterialNames(updatedMaterialNames);
        setRemainingIds(updatedRemainingIds);

        // Call onUpdate after updating the IDs
        onUpdate(updatedRemainingIds);
    };

    // Update level with the remaining material IDs
    const onUpdate = async (updatedIds) => {
        try {
            const res = await dispatch(Update_Level({ _id: item._id, name: item.name, materialIDS: updatedIds }));
            if (res.data.case) {
                console.log("Level updated successfully");
            } else {
                console.log("Failed to update level");
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <FaEye variant="primary" onClick={handleShow} size='25px' />

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Show Materials</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive className='text-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>اسم الدرس</th>
                                <th>حذف الدرس</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materialNames.map((material, index) => (
                                <tr key={material._id}>
                                    <td>{index + 1}</td>
                                    <td>{material.name}</td>
                                    <td>
                                        <Button
                                            variant="danger"
                                            onClick={() => handleDelete(material._id)}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ShowMateriaLevel;
