import React, { useEffect, useState } from "react";
// import Col from "../styled-components/col";
// import Contain from "../styled-components/contain";
// import Logo from "../../assets/logo.png";
import { OLP_White_Logo as Logo, platformName } from "../../constants.js"
import "./header.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import NavButton from "../styled-components/navButton";
import { Log_Out } from "../../actions/login&register";
// import Button from "../styled-components/button";
// import { Set_Lang } from "../../actions/translation.js";
// import LangSwitcher from "../translation/languageSwitcher";
import { AiOutlineMenu } from 'react-icons/ai';
import SideNave from '../Home/mobileMenu.js'
import profileAvatar from '../../assets/profile-avatar.png'
import GetText from '../translation/getText.jsx'

//
const publicPages = [
  { name: "الرئيسية", to: "/" },
  { name: "البرامج الدراسية", to: "/subscription" },
  { name: "من نحن؟", to: "/about" },
  //{ name: "teachers", to: "/teachers" },
];
const studentPages = [
  { name: "الرئيسية", to: "" },
  { name: "Booking", to: "/booking" },
  { name: "Course", to: "/course" },
  { name: "messages", to: "/messages" },
  { name: "meeting", to: "/call" },
];
const teacherPages = [
  { name: "الرئيسية", to: "" },
  { name: "working-schedual", to: "/teacher-table" },
  { name: "meeting", to: "/call" },
  { name: "Upload file", to: "/upload" },
  { name: "Upload Video", to: "upload-video" },
  { name: "bookings", to: "/bookings" },
  { name: "messages", to: "/messages" },
];
const adminPages = [
  ...publicPages,
  { name: "Admin Dashboard", to: "/admin-dashboard" },
];
//
// console.log(platformName)
function Header({ menuColor }) {
  const user = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    dispatch(Log_Out());
    navigate("/");
  };


  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (

    <header id="teach-header">
      {windowSize.width > 1000 ?
        <>
          <List menuColor={menuColor} />
          <ul className="action-list-b">
            <li id="list-b">
              {/* {user.auth ? (
            <a href="/" onClick={onClick}>
              <Button
                name={user.username + " log out"}
                bgColor="#293476"
                width="100px"
              />
            </a>
          ) : (
            <NavButton
              to="/login"
              name="Call us"
              width="170px"
              bgColor="#293476"
              perfix = 'header-call-us'
            />
          )} */
              }
              <img src={profileAvatar} alt='' id='userIMG' />
            </li>

            {/*<li id="list-b">
          <LangSwitcher color={'#333'} bgr={'#ffffff'} iconColor={'#333333'}
          marginRight='-0px' />
        </li>*/}
          </ul>
        </> : <HeaderMobile />}
    </header>
  );
}
//-----List Component-------
const List = ({ menuColor }) => {
  const user = useSelector(mapStateToProps);

  const pages = () => {
    if (user.role === "student") {
      return studentPages;
    } else if (user.role === "teacher") return teacherPages;
    else if (user.role === "Admin") return adminPages;
    else {
      return publicPages;
    }
  };
  return (
    <GetText>
      <nav>
        <ul className="list">
          <li className="list-btn">
            <a href="/">
              {" "}
              <img src={Logo} alt={platformName} className="header-logo" />
            </a>
          </li>
          {publicPages.map((p, i) => (
            <li className="nav-list-tap" key={i}>
              <NavLink id={'header' + i} className="list-btn" to={p.to} style={{ color: menuColor }}>
                {p.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </GetText>
  );
};


// mobile view

function HeaderMobile() {

  const user = useSelector(mapStateToProps);
  const [openSide, setOpenSide] = useState(false);//
  const pages = () => {
    if (user.role === "student") {
      return studentPages;
    } else if (user.role === "teacher") return teacherPages;
    else if (user.role === "admin") return adminPages;
    else {
      return publicPages;
    }
  };
  //
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onClick = () => {
    dispatch(Log_Out());
    navigate("/");
  };
  return (
    <>
      <a href="/">
        {" "}
        <img src={Logo} alt={platformName} width="70px" />
      </a>
      {/*{ user?.auth&&<span onClick={()=>{
        onClick()
        }}>
        خروج
        </span>}*/}
      <AiOutlineMenu color='#333' size='40px' onClick={() => { setOpenSide(true) }} />
      <SideNave open={openSide} onClose={() => { setOpenSide(false) }} logo={Logo} main={[...publicPages, { name: "login/register", to: "/login" }]} />
    </>
  )
}
const mapStateToProps = ({ loggedUser, language }) => {
  return { ...loggedUser.user, auth: loggedUser.auth };
};
export default Header;
