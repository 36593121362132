import React from "react"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { HomePage } from "../../translations/trans";
import { useSelector } from "react-redux";
import imagereset from "../../assets/images/resetPass.png"
import "./login.css";
import { forgetPassByWhatsapp } from "../../utils/API";
import { ErrorMsg, success } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";
const ModalforgetPass = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [phone, setPhone] = useState(null)
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const navigate = useNavigate();

    const forgetPass = async () => {
        try {
            const res = await forgetPassByWhatsapp({
                phone: phone
            })
            console.log(res)
            if (res?.data?.case) {
                success(res?.data?.message)
                navigate(`/reset-password/${phone}`)
            }
        } catch (e) {
            console.log(e)

            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    }
    return (
        <div>
            <span onClick={handleShow} style={{ cursor: "pointer" }}>{HomePage.forgetPass[lang]}?</span>

            <Modal show={show} onHide={handleClose} animation={false} centered size="lg" >
                <Modal.Body>
                    <div className="row justify-content-between align-items-center" dir={`${dir}`}>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div>
                                <h4>Password Recovery</h4>
                                <p>A message has been sent to the password reset.
                                    A password reset message has been sent to the email address in your account file. But it may take several minutes to appear in your inbox. Please wait at least 10 minutes before attempting another reset  </p>
                                <div>
                                    <label id='logla1' htmlFor='login1'>
                                        {HomePage.phone[lang]}
                                    </label>
                                    <br />
                                    <input type='text' name='phone' onChange={(e) => setPhone(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                                </div>
                                <div className="text-center">
                                    <button className="btn w-50 mt-3" onClick={forgetPass} style={{ background: "#283476", color: "white" }}>Send</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <img src={imagereset} alt="data" className="w-100" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ModalforgetPass