import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { sendMessageWhatsApp } from '../../utils/class';
// import { ErrorMsg } from '../../utils/Toast';
import { BsWhatsapp } from "react-icons/bs";
import { sendMessageWhatsApp } from '../../../utils/class';
import { ErrorMsg,success } from '../../../utils/Toast';
const SendMsgToClass = ({ Class }) => {

    const [message, setMessage] = useState("");
    const [type, setType] = useState(null);
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAddMessageWhats = async () => {
        try {
            const res = await sendMessageWhatsApp({
                classId: Class?._id,
                message: message,
                type: type,
                isAll: true
            })
            if(res?.data?.case){
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            console.log(e)
            if (e?.response) {
                ErrorMsg("هناك مشكله")
            }
        }
    }
    return (
        <div>
            <BsWhatsapp color={"#25D366"} size={"25"} onClick={handleShow} />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <h5>من فضلك قم بادخال الرساله</h5>

                    <div>
                        <select onChange={(e) => setType(e.target.value)} value={type} className='w-100 form-control mb-3 mt-3'>
                            <option value={1}>ارسل الي الطالب</option>
                            <option value={2}>ارسل الي الوالد</option>
                            <option value={3}>ارسال الي الطالب  والوالد</option>
                        </select>
                    </div>

                    <div>
                        <textarea row={"8"} style={{ resize: "none" }} className='w-100 form-control' value={message} onChange={(e) => setMessage(e.target.value)}>

                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        إلغاء
                    </Button>
                    <Button variant="primary" onClick={handleAddMessageWhats}>
                        اضافه
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SendMsgToClass
