import React, { useEffect, useState } from "react"
import { getResultByUserByTest } from "../../../utils/results";

const ShowTestResult = ({ userId, test }) => {
    const [showResult, setShowResult] = useState([])
    const [resultError, setResultError] = useState(null)
    const [resultErrorMsg, setResultErrorMsg] = useState(null)
    const getTestUser = async () => {
        try {
            const res = await getResultByUserByTest({
                userID: userId,
                testID: test?._id
            });
            console.log(res)
            if (res?.data?.case) {
                setShowResult(res?.data?.result)
            } else {

                setResultError(false)
                setResultErrorMsg(res?.data?.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getTestUser();
    }, [test?._id])
    return (
        <div>

            <div>
                <h2>نتيجة الامتحان {test?.name}</h2>
                <div className="mx-auto my-auto">
                    <div dir="rtl" className="v-flex justify-content-between">
                        <h4>حالة الاختبار: {showResult.passed ? " ناجح " : " راسب "}</h4>
                        <h4>الدرجة: {showResult.result} / {showResult.total} </h4>
                        <h4>
                            عدد المحاولات :<span className="mx-2">{`${showResult?.tries}`}</span>{" "}
                        </h4>
                    </div>

                    {showResult?.questions?.map((answer, i) => (
                        <div key={i} dir='ltr'>
                            <h3 className="my-4 w-200">{answer.question}</h3>
                            <div className="row">
                                {answer.choices.map((c, index) => (
                                    <div
                                        key={index}
                                        className={`col-sm-2 answer mx-1 p-2 ${c.correct === true ?
                                            "correctAnswer" : ""
                                            } ${!c.correct && c.value === answer.chosenAnswer
                                                ? "wrongAnswer"
                                                : ""
                                            }`}
                                    >
                                        {c.value}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>



        </div>
    )
}


export default ShowTestResult
