import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { HomePage } from "../../translations/trans";
import { ErrorMsg, success } from "../../utils/Toast";
import BlueHeader from '../publicPages/blueHeader';
import Header from '../Home/header';
import Footer from '../Home/footer';
import { resetPass } from '../../utils/API';
import { useParams } from 'react-router-dom';
const ResetPasswordPage = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const params = useParams()
    const [confirmPassData, setConfirmPassData] = useState(null)
    const [newPass, setPass] = useState(null)
    const [code, setCode] = useState(null)

    const handleResetPass = async () => {
        try {
            if (newPass === confirmPassData) {
                const res = await resetPass({
                    phone: params?.phone,
                    newPassword: newPass,
                    code: code
                })
                if (res?.data?.case) {
                    success(res?.data?.message)
                }
            } else {
                ErrorMsg(`يرجي التاكد من كتابة كلمة السر`)
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data) {
                ErrorMsg(e?.response?.data?.message)
            }
        }
    }
    return (
        <div>
            <Header />
            <BlueHeader title={`${HomePage.resetPass[lang]}`} subTitle={`${HomePage.home[lang]}`} secondSubTitle={`${HomePage.resetPass[lang]}`} />
            <div className='resetPass m-auto shadow mt-5 mb-5' style={{ background: "white", borderRadius: "10px", padding: "10px" }}>
                <div className="" dir={`${dir}`}>
                    <div className="">
                        <div>

                            <div>
                                <label id='logla1' htmlFor='login1'>
                                    {HomePage.Newpass[lang]}
                                </label>
                                <br />
                                <input type='password' name='newPass' value={newPass} onChange={(e) => setPass(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                            </div>
                            <div>
                                <label id='logla1' htmlFor='login1'>
                                    {HomePage.confirmPass[lang]}
                                </label>
                                <br />
                                <input type='password' value={confirmPassData} name='phone' onChange={(e) => setConfirmPassData(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                            </div>
                            <div>
                                <label id='logla1' htmlFor='login1'>
                                    {HomePage.code[lang]}
                                </label>
                                <br />
                                <input type='text' name='code' value={code} onChange={(e) => setCode(e.target.value)} className="form-control" style={{ borderRadius: "20px" }} id='login1' />
                            </div>
                            <div className="text-center">
                                <button className="btn w-50 mt-3" onClick={handleResetPass} style={{ background: "#283476", color: "white" }}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ResetPasswordPage
