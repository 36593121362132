// import React from 'react'
// import { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { getLessonById } from '../../../utils/lessons';
// const ModalUpdateLesson = ({ lessonId }) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     // Fetch Lesson
//     const [lesson, setLesson] = useState([]);
//     const getLesson = async () => {
//         try {
//             const res = await getLessonById({
//                 _id: lessonId,
//             })
//             setLesson(res?.data?.lesson)
//         } catch (e) {
//             console.log(e)
//         }
//     }
//     useEffect(() => {
//         getLesson();
//     }, [lessonId])

//     console.log(lesson)
//     return (
//         <div>
//             <span variant="primary" style={{ cursor: "pointer" }} onClick={handleShow}>
//                 تحديث محتوي الدرس
//             </span>

//             <Modal show={show} onHide={handleClose} centered size="lg">
//                 <Modal.Header>
//                     <Modal.Title>تحديث محتوي الدرس</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>

//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button variant="primary" onClick={handleClose}>
//                         Save Changes
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     )
// }

// export default ModalUpdateLesson




import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getLessonById, updateLesson } from '../../../utils/lessons';
import { urlBase } from '../../../utils/API';
import { Form } from 'react-bootstrap';
import { HomePage } from '../../../translations/trans';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ModalUpdateLesson = ({ lessonId, materialCode }) => {
    const [show, setShow] = useState(false);
    const [lesson, setLesson] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Fetch Lesson
    const getLesson = async () => {
        try {
            const res = await getLessonById({ _id: lessonId });
            setLesson(res?.data?.lesson);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (lessonId) getLesson();
    }, [lessonId]);

    const preventContextMenu = (e) => {
        e.preventDefault();
    };


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [lessonName, setLessonName] = useState('');

    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [pdf, setPdf] = useState(null);

    const [imageUrl, setImageUrl] = useState();
    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState(0); // State to handle upload progress

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLessonNameChange = (e) => {
        setLessonName(e.target.value);
    };


    const handleFileChange = (type) => {
        console.log(type)
        switch (type) {
            case 'image':
                setMessage('Uploading image...');
                break;
            case 'video':
                setMessage('Uploading video...');
                break;
            case 'pdf':
                setMessage('Uploading PDF...');
                break;
            default:
                setMessage('Uploading...');
        }

        // You may want to handle the file upload process here
        // For now, let's assume the file upload happens instantly
    };

    //-------------
    //                                 LOCAL UPLOAD ENDPOINT
    const uploadingFileLocally = (data) => {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/upload-file-locally`,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentage)

            }
        })
    }
    //-------------

    // const AWSUpload = async (file)=>{
    //   try{
    //            const params = {
    //         Bucket: 'osbash-demo1',
    //         Key: file.name,
    //         Body: file
    //       };
    //       const command = new PutObjectCommand(params);
    //       const data = await s3.send(command);
    //       // alert('File uploaded successfully');
    //   }
    //   catch(err){
    //     console.log(err.message)
    //   }
    // }
    // console.log(video)

    // const handleSaveChanges = async () => {
    //     try {
    //         setLoading(true);

    //         let content = [];

    //         if (image) {
    //             // await AWSUpload(image)
    //             handleFileChange(image.type)
    //             const res = await uploadingFileLocally(image)
    //             console.log(res);
    //             // await uploadReact_S3(image);
    //             content.push({ name: `${res.data?.data?.name}.${res.data?.data?.type}`, type: image.type, source: res.data.data.source });

    //         }
    //         if (video) {

    //             handleFileChange(video.type)
    //             const res = await uploadingFileLocally(video)

    //             content.push({ name: `${res.data?.data?.name}.${res.data?.data?.type}`, type: video.type, source: res.data.data.source });

    //         }
    //         if (pdf) {

    //             handleFileChange(pdf.type)
    //             const res = await uploadingFileLocally(pdf)

    //             content.push({ name: `${res.data?.data?.name}.${res.data?.data?.type}`, type: pdf.type, source: res.data.data.source });

    //         }

    //         const lessonData = {
    //             id: lessonId,
    //             materialCode: materialCode,
    //             name: lessonName,
    //             content,
    //             testIDS:[]
    //         };

    //         const response = await updateLesson(lessonData);
    //         setMessage(response.data.message);
    //         setLoading(false);

    //         handleCloseModal();
    //         alert(response.data.message);
    //     } catch (error) {
    //         console.error('Error:', error);
    //         setMessage(error.message);
    //         setLoading(false);
    //     }
    // };
    const handleSaveChanges = async () => {
        try {
            setLoading(true);

            // Start with the existing content of the lesson
            let content = [...lesson.content];

            // Handle image upload
            if (image) {
                handleFileChange(image.type);
                const res = await uploadingFileLocally(image);
                content = content.filter(c => !c.type.split('/').includes('image')); // Remove old image content
                content.push({
                    name: `${res.data?.data?.name}`,
                    type: image.type,
                    source: res.data.data.source,
                });
            }

            // Handle video upload
            if (video) {
                handleFileChange(video.type);
                const res = await uploadingFileLocally(video);
                content = content.filter(c => !c.type.split('/').includes('video')); // Remove old video content
                content.push({
                    name: `${res.data?.data?.name}`,
                    type: video.type,
                    source: res.data.data.source,
                });
            }

            // Handle PDF upload
            if (pdf) {
                handleFileChange(pdf.type);
                const res = await uploadingFileLocally(pdf);
                content = content.filter(c => !c.type.split('/').includes('pdf')); // Remove old pdf content
                content.push({
                    name: `${res.data?.data?.name}`,
                    type: pdf.type,
                    source: res.data.data.source,
                });
            }

            const lessonData = {
                id: lessonId,
                materialCode: materialCode,
                name: lessonName || lesson.name, // Use the old name if no new name is provided
                content,
                testIDS: []
            };

            const response = await updateLesson(lessonData);
            setMessage(response.data.message);
            setLoading(false);

            handleCloseModal();
            alert(response.data.message);
        } catch (error) {
            console.error('Error:', error);
            setMessage(error.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <span variant="primary" style={{ cursor: 'pointer' }} onClick={handleShow}>
                تحديث محتوي الدرس
            </span>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header>
                    <Modal.Title>تحديث محتوي الدرس</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="lessonName">
                        <Form.Label>{HomePage.nameLesson[lang]}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ادخل اسم الدرس"
                            value={lessonName}
                            onChange={handleLessonNameChange}
                            multiple={false}
                        />
                    </Form.Group>

                    <Form.Group controlId="lessonFile">
                        <Form.Label>{HomePage.uploadImage[lang]}</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(event) => setImage(event.target.files[0])}
                        />
                    </Form.Group>

                    <Form.Group controlId="lessonFile">
                        <Form.Label>{HomePage.uploadVideo[lang]}</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(event) => setVideo(event.target.files[0])}
                        />
                    </Form.Group>

                    <Form.Group controlId="lessonFile">
                        <Form.Label>{HomePage.uploadPdf[lang]}</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(event) => setPdf(event.target.files[0])}
                        />
                    </Form.Group>

                    <Button variant="primary" onClick={handleSaveChanges}>
                        {HomePage.addLesson[lang]}
                    </Button>
                    {lesson?.content?.length > 0 ? (
                        lesson?.content.map((c, i) => {
                            if (c.type.split('/').includes('image')) {
                                return (
                                    <div key={i} onContextMenu={preventContextMenu}>
                                        <hr />
                                        <img
                                            src={`${urlBase}/${c.name}`}
                                            alt="lesson cover"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                );
                            } else if (c.type.split('/').includes('video')) {
                                return (
                                    <div key={i} style={{ marginBottom: '20px', marginTop: "40px" }} onContextMenu={preventContextMenu}>
                                        <hr />
                                        <video controls controlsList="nodownload" width="100%" height="auto">
                                            <source src={`${urlBase}/api/files/${c.name}?type=${c?.type}`} type={c.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                );
                            } else if (c.type.split('/').includes('pdf')) {
                                return (
                                    <div key={i} style={{ marginBottom: '20px', marginTop: "40px", width: '100%' }} onContextMenu={preventContextMenu}>
                                        <div className="pdf-container">
                                            <iframe
                                                style={{ backgroundColor: '#fff' }}
                                                src={`${urlBase}/${c.name}`}
                                                width="100%"
                                                height="1200px"
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModalUpdateLesson;
