import React, { useState } from 'react';
import { Form, FormControl, FormGroup, FormLabel, Button, FormCheck, Container, Alert } from 'react-bootstrap';
import './tests.css';
import { useSelector } from 'react-redux';
import { createTest , deleteTest, updateTest } from '../../../utils/tests';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { success } from '../../../utils/Toast';
import ModalDeleteExam from './ModalDeleteExam';

function AddTest({lesson}) {

  const [message, setMessage] = useState('');
  const [testName, setTestName] = useState(lesson?.name || "");
  const [totalDegree, setTotalDegree] = useState(lesson?.tot_degree || 0);
  const [maxTries, setMaxTries] = useState(lesson?.triesMax|| 1);
  const [questions, setQuestions] = useState(lesson?.questions || [
    {
      question: '',
      degree: '',
      file: '',
      choices: [
        { value: '', correct: false },
        { value: '', correct: false },
        { value: '', correct: false },
        { value: '', correct: false },
      ],
    },
  ]);

  const [alertVariant, setAlertVariant] = useState(null);

  const loggedUser = useSelector((state) => state.loggedUser);

  const handleTestNameChange = (e) => {
    setTestName(e.target.value);
  };

  const handleTotalDegreeChange = (e) => {
    setTotalDegree(Math.abs(e.target.value));
  };

  const handleQuestionChange = (e, questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].question = e.target.value;
      return updatedQuestions;
    });
  };

  const handleDegreeChange = (e, questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].degree = Math.abs(e.target.value);
      return updatedQuestions;
    });
  };

  const handleChoiceChange = (e, questionIndex, choiceIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].choices[choiceIndex].value = e.target.value;
      return updatedQuestions;
    });
  };

  const handleRadioChange = (questionIndex, choiceIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].choices = updatedQuestions[questionIndex].choices.map(
        (choice, i) => {
          return { ...choice, correct: i === choiceIndex };
        }
      );
      return updatedQuestions;
    });
  };

  const uploadingFileLocally = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
    return response.data.data.name;
  };

  const handleFileChange = async (e, questionIndex) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = await uploadingFileLocally(file);
      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[questionIndex].file = fileName;
        return updatedQuestions;
      });
    }
  };

  const addQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        question: '',
        degree: '',
        file: '',
        choices: [
          { value: '', correct: false },
          { value: '', correct: false },
          { value: '', correct: false },
          { value: '', correct: false },
        ],
      },
    ]);
  };

  const deleteLastQuestion = () => {
    if (questions.length > 1) {
      setQuestions((prevQuestions) => prevQuestions.slice(0, -1));
    }
  };

  const { testFor } = useSelector(({ view }) => {
    return { testFor: view?.testFor };
  });

  // const submitTest = async () => {
  //   try {
  //     const testData = {
  //       test: {
  //         name: testName,
  //         tot_degree: totalDegree,
  //         questions: questions,
  //       },
  //       testFor,
  //     };
  //     const res = await createTest(testData);
  //     setMessage(res.data.message);
  //     setAlertVariant(res.data.case ? 'success' : 'danger');
  //   } catch (err) {      
  //     console.log(err);
  //   }
  // };

  const submitTest = async () => {
    try {
      const sumOfQuestionDegrees = questions.reduce((sum, question) => sum + Number(question.degree), 0);
      
      if (sumOfQuestionDegrees !== Number(totalDegree)) {
        setMessage('The sum of question degrees must equal the total test degree.');
        setAlertVariant('danger');
        return;
      }
  
      if(!lesson){
        const testData = {
        test: {
          name: testName,
          tot_degree: totalDegree,
          important: true,
          triesMax:maxTries,
          questions: questions,
        },
        testFor,
      };
      const res = await createTest(testData);
      setMessage(res.data.message);
      setAlertVariant(res.data.case ? 'success' : 'danger');
      }else{
        const testData = {

          // test: {
            _id:lesson?._id,
            name: testName,
            tot_degree: totalDegree,
            important: true,
            triesMax:maxTries,
            questions: questions,
          // },
          // testFor,
        };
        const res = await updateTest(testData);
        setMessage(res.data.message);
        setAlertVariant(res.data.case ? 'success' : 'danger');
      }
    } catch (err) {
      console.log(err);
    }
  };
  



  

  return (
    <Container>
      {message && (
        <Alert variant={alertVariant} onClose={() => setMessage('')} dismissible>
          {message}
        </Alert>
      )}

      {
        lesson &&(
          <ModalDeleteExam idTest={lesson?._id}/>
        )
      }

      <FormGroup className="mt-3">
        <FormLabel>Test Name:</FormLabel>
        <FormControl type="text" value={testName} onChange={handleTestNameChange} />
      </FormGroup>

      <FormGroup className="mt-3">
        <FormLabel>Total Degree:</FormLabel>
        <FormControl type="number" value={totalDegree} min={1} onChange={handleTotalDegreeChange} />
      </FormGroup>

      <FormGroup className="mt-3">
        <FormLabel>Max Tries:</FormLabel>
        <FormControl type="number"  min={1} value={maxTries} onChange={(e)=>{setMaxTries(e.target.value)}} />
      </FormGroup>

      <br />
      <div className='align-evenly'>
        <Button onClick={addQuestion} variant="primary">
          Add Question
        </Button>
        <Button onClick={deleteLastQuestion} variant="danger" style={{ marginLeft: '10px' }}>
          Delete Last Question
        </Button>
      </div>

      {questions.map((question, questionIndex) => (
        <Form key={questionIndex} style={{ marginTop: '20px' }}>
          <FormGroup>
            <FormLabel style={{ backgroundColor: 'red', color: '#fff', padding: '5px', borderRadius: '5px' }}>
              Question {questionIndex + 1}:
            </FormLabel>
            <FormControl type="text" value={question.question} onChange={(e) => handleQuestionChange(e, questionIndex)} />
          </FormGroup>

          <FormGroup>
            <FormLabel>File Name:</FormLabel>
            <FormControl type="file" className='form-control mb-3' onChange={(e) => handleFileChange(e, questionIndex)} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Question Degree:</FormLabel>
            <FormControl type="number" value={question.degree} min={1} onChange={(e) => handleDegreeChange(e, questionIndex)} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Choices:</FormLabel>
            {question.choices.map((choice, choiceIndex) => (
              <div key={choiceIndex}>
                <FormControl
                  type="text"
                  value={choice.value}
                  placeholder={`Choice ${choiceIndex + 1}`}
                  onChange={(e) => handleChoiceChange(e, questionIndex, choiceIndex)}
                />
                <FormCheck
                  type="radio"
                  label={``}
                  className='test-radio'
                  checked={choice.correct}
                  onChange={() => handleRadioChange(questionIndex, choiceIndex)}
                />
              </div>
            ))}
          </FormGroup>
        </Form>
      ))}

      <Button onClick={submitTest} variant="success" style={{ marginTop: '20px' }}>
        Submit Test
      </Button>
      <hr />
      <hr />
    </Container>
  );
}

export default AddTest;
