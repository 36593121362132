import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Hls from 'hls.js';
import { urlBase } from '../../../utils/API';

const VideoPlayer = ({ videoPath, userId }) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null); // Keep a reference to the HLS instance
  const [videoUrl, setVideoUrl] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenExpiryTime, setTokenExpiryTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(0); // State to store current video time

  // Fetch the initial signed URL with a JWT token
  const getSignedUrl = async () => {
    try {
      const response = await axios.post(`${urlBase}/api/videos/refresh-token`, {
        video: videoPath,
        userId
      });
      setVideoUrl(response.data.url);
      setToken(response.data.token);
      setTokenExpiryTime(Date.now() + response.data.expiresIn * 1000); // Set expiry from server
    } catch (error) {
      console.error('Error fetching signed URL:', error);
    }
  };

  // Refresh the JWT token before it expires
  const refreshToken = async () => {
    try {
      // Save the current playback position before refreshing the token
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
      }

      const response = await axios.post(`${urlBase}/api/videos/refresh-token`, {
        video: videoPath,
        userId
      });

      setVideoUrl(response.data.url); // Update video URL

      // Reload the video source without recreating the HLS instance
      if (hlsRef.current) {
        hlsRef.current.loadSource(response.data.url);
        hlsRef.current.startLoad();
      }

      setToken(response.data.token);
      setTokenExpiryTime(Date.now() + response.data.expiresIn * 1000); // Update expiry
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  // Periodically refresh the token based on expiration time
  useEffect(() => {
    if (tokenExpiryTime) {
      const currentTime = Date.now();
      const timeUntilExpiry = tokenExpiryTime - currentTime;

      // Refresh token 5 seconds before expiry
      if (timeUntilExpiry > 0) {
        const timer = setTimeout(() => {
          refreshToken(); // Refresh token slightly before it expires
        }, timeUntilExpiry - 5000);

        return () => clearTimeout(timer); // Cleanup the timeout
      }
    }
  }, [tokenExpiryTime]);

  // Load and configure the HLS stream with dynamic key retrieval using JWT token
  useEffect(() => {
    if (videoUrl && Hls.isSupported()) {
      if (!hlsRef.current) {
        const hls = new Hls();
        hlsRef.current = hls; // Store HLS instance in ref

        // Use xhrSetup to pass the JWT token in the headers for key requests
        hls.config.xhrSetup = (xhr, url) => {
          if (url.includes('/keys')) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
          if (url.includes('/streem-hls')) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        };

        hls.loadSource(videoUrl);
        hls.attachMedia(videoRef.current);

        // Restore the video playback position after loading the new source
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          if (videoRef.current && currentTime > 0) {
            videoRef.current.currentTime = currentTime; // Restore the saved playback time
            videoRef.current.play(); // Resume playback
          }
        });

        // Handle HLS errors
        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            console.error('Fatal HLS error:', data);
            hls.destroy();
          }
        });
      }
    }

    return () => {
      // Cleanup HLS instance when component unmounts
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [videoUrl, token]);

  // Load the signed URL when the component mounts
  useEffect(() => {
    getSignedUrl();
  }, [videoPath, userId]);

  return (
    <div>
      {videoUrl ? (
        <video ref={videoRef} controls style={{ width: '100%' }} />
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
};

export default VideoPlayer;
