import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { CiSquareRemove } from 'react-icons/ci';
import { Update_Package } from '../../../actions/packages';
import Loading from '../../loadingEffect';

function Edit({ pack, currency }) {
  const [choosedLessons, setChoosedLessons] = useState([]);
  const [remLessons, setRemLessons] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const getAllLessons = async () => {
    try {
      const res = await getLessonsOfSpecificMaterial({ code: pack.materialCode });
      const remeaningLessons = res.data.lessons.filter(l => {
        const _id = l._id
        return !pack.selectedLessons.includes(_id)
      })
      console.log('hhh', res.data, remeaningLessons)
      const Choosed = res.data.lessons.filter(l => {
        const _id = l._id
        return pack.selectedLessons.includes(_id)
      })
      setLessons(res.data.lessons);
      setRemLessons(remeaningLessons);
      setChoosedLessons(Choosed);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllLessons();
  }, []);
  const onChangeLessons = (id) => {
    const rem = remLessons.filter((lesson) => lesson._id !== id);
    const choosed = remLessons.filter(l => l._id === id)
    setChoosedLessons([...choosedLessons, ...choosed]);
    setRemLessons(rem);
  };

  const onRemoveLesson = (id) => {
    const updatedSelectedLessons = choosedLessons.filter((lesson) => lesson._id !== id);
    setChoosedLessons(updatedSelectedLessons);
    const remLesson = lessons.filter(l => l._id === id)
    const rem = [...remLessons, ...remLesson];
    setRemLessons(rem)
  };

  const handleSave = async () => {
    try {
      if (!choosedLessons.length) return alert('لم تختر اى دروس!')
      // Handle save logic here
      const lessonsIDS = choosedLessons.map(l => l._id);
      pack.active = false
      const res = await dispatch(Update_Package({ ...pack, selectedLessons: lessonsIDS }))
      alert(res.data.message)
      handleCloseModal();

    }
    catch (err) {
      console.log(err.message)
    }
  };

  return (
    <>
      <Button style={{ width: '70px', backgroundColor: '#fff', color: 'gray' }} onClick={handleShowModal}>تعديل</Button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل البرنامج</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="lessonsSelect">
              <Form.Label>الدروس</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLessons(e.target.value)}
              >
                <option value={null}> اختر الدرس </option>
                {remLessons.map((lesson) => (
                  <option key={lesson._id} value={lesson._id}>
                    {lesson.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="selectedLessons">
              <Form.Label> الدروس المضافة</Form.Label>
              {choosedLessons.map((lesson) => (
                <div key={lesson._id}>
                  <Form.Label>{lesson.name}</Form.Label>
                  <CiSquareRemove onClick={() => onRemoveLesson(lesson._id)} size={30} color='#999' />
                </div>
              ))}
            </Form.Group>
          </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>اغلاق</Button>
          <Button variant="primary" onClick={handleSave}>حفظ التغييرات </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Edit;
