import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { GetMaterialsByIds } from '../../../utils/materials';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { CiSquareRemove } from 'react-icons/ci';
import { Create_New_Package } from '../../../actions/packages';
import { BoxLoading } from 'react-loadingg';
import Loading from '../../loadingEffect';
import { HomePage } from '../../../translations/trans';
import axios from "axios"
import { urlBase } from '../../../utils/API';
function AddPackage({ currency }) {
  const [name, setName] = useState(null)
  const [materials, setMaterials] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [image, setImage] = useState(null);
  const [cover, setCover] = useState(null);
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [selectedValues, setSelectedValues] = useState({
    levelId: '',
    materialCode: '',
    selectedLessons: [],
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null)
  const dispatch = useDispatch();

  const { levels } = useSelector(({ levels }) => ({ levels }));

  const resetAllValues = () => {
    setName(null);
    setMaterials([]);
    setLessons([]);
    setAllLessons([]);
    setPrice(0);
    setSelectedValues({
      levelId: '',
      materialCode: '',
      selectedLessons: [],
    })
  }
  const onChangeLevel = async (levelId) => {
    try {
      if (!levelId || levelId.trim() === '') return;
      const selectedLevel = levels.find((level) => level._id === levelId);
      const res = await GetMaterialsByIds({ IDs: selectedLevel.materialIDS });
      const { materials } = res.data;
      setMaterials(materials);
      setSelectedValues({
        levelId,
        materialCode: '',
        selectedLessons: [],
      });
    } catch (err) {
      console.log(err.message)
    }
  };

  const onChangeMaterial = async (materialCode) => {
    const res = await getLessonsOfSpecificMaterial({ code: materialCode });
    const { lessons } = res.data;
    setLessons(lessons);
    setAllLessons(lessons);
    setSelectedValues({
      ...selectedValues,
      materialCode,
      selectedLessons: [],
    });
  };

  const onChangeLessons = (id) => {
    const updatedLessons = lessons.filter((lesson) => lesson._id !== id);

    setSelectedValues({
      ...selectedValues,
      selectedLessons: [...selectedValues.selectedLessons, id],
    });
    setLessons(updatedLessons);
  };

  const onRemoveLesson = (id) => {
    const removedLesson = selectedValues.selectedLessons.find((lessonId) => lessonId === id);
    const updatedSelectedLessons = selectedValues.selectedLessons.filter((lessonId) => lessonId !== id);

    setLessons([...lessons, allLessons.find((lesson) => lesson._id === removedLesson)]);
    setSelectedValues({
      ...selectedValues,
      selectedLessons: updatedSelectedLessons,
    });
  };

  const onChangePrice = (price) => {
    setPrice(price)
  }
  const onChangeDiscountPrice = (price) => {
    setDiscountPrice(price)
  }

  const loggedUser = useSelector((state) => state.loggedUser);

  const uploadingFileLocally = async (image) => {
    const formData = new FormData();
    formData.append("file", image);
    const response = await axios.post(`${urlBase}/api/upload-file-locally?userId=${loggedUser?.user?._id}`, formData);
    console.log(response)
    return response.data.data.name;
  };


  // SUBMITE
  const handleSubmit = async (e) => {
    try {

      e.preventDefault();
      if (!name || !price || !selectedValues.selectedLessons.length) return alert('من فضلك قم باكمال البيانات المطلوبة!')
      setLoading(true)
      const fileName = await uploadingFileLocally(image)
      console.log(fileName)
      const data = { ...selectedValues, price, name, coverImage: fileName , discountPrice }
      const res = await dispatch(Create_New_Package(data));
      resetAllValues();
      setMessage(res.data.message);
      // Dispatch actions or perform further actions here
      setLoading(false)
    }
    catch (err) {
      console.error(err);
      setMessage(err.message);
      setLoading(false)
    }
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <Container className='loading-parent' style={{ border: '0px solid red', width: '100%' }}>
      <h3>{HomePage.addNewProgram[lang]}</h3>

      {loading ? <Loading /> : <></>}
      <Form onSubmit={handleSubmit}>
        <Row>

          <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>{HomePage.packageName[lang]} </Form.Label>
              <Form.Control
                type="text"
                placeholder={HomePage.packageName[lang]}
                style={{ width: '150px' }}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <hr/>
          </Col>

          <Col md={12}>
            <Form.Group controlId="program-cover-image">
              <Form.Label>صورة البرنامج </Form.Label>
              <Form.Control
                type="file"
                onChange={(event) => {
                  setImage(event.target.files[0]);
                  const url = window.URL.createObjectURL(event.target.files[0]);
                  setCover(url)

                }}
              />
            </Form.Group>
            {cover ?
              <img width="300px" src={cover} alt="imageprogram" /> : <></>}
          </Col>

          <Col md={12}>
            <Form.Group controlId="levelSelect">
              <Form.Label> {HomePage.chooseLevel[lang]}</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLevel(e.target.value)}
              >
                <option value="">{HomePage.chooseLesson[lang]}</option>
                {levels.map((level) => (
                  <option key={level._id} value={level._id}>
                    {level.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="materialSelect">
              <Form.Label>{HomePage.price[lang]} {currency ? currency : 'ج.م'}</Form.Label>
              <Form.Control
                type="number"
                placeholder='سعر الباقة'
                style={{ width: '150px' }}
                onChange={(e) => onChangePrice(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>{HomePage.discountPrice[lang]} {currency ? currency : 'ج.م'}</Form.Label>
              <Form.Control
                type="number"
                placeholder='سعر الخصم'
                style={{ width: '150px' }}
                onChange={(e) => onChangeDiscountPrice(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>{HomePage.material[lang]}</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeMaterial(e.target.value)}
              >
                <option value={null}> {HomePage.chooseMaterial[lang]}</option>
                {materials.map((material) => (
                  <option key={material.code} value={material.code}>
                    {material.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="lessonsSelect">
              <Form.Label>{HomePage.lessons[lang]}</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLessons(e.target.value)}
              >
                <option value={null}>Select lessons</option>
                {lessons.map((lesson) => (
                  <option key={lesson._id} value={lesson._id}>
                    {lesson.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ marginTop: '20px' }}>
            <div>
              <strong>Chosen Lessons:</strong>
              {selectedValues.selectedLessons.map((selectedLesson) => (
                <div key={selectedLesson}>
                  <span>
                    {allLessons.find((lesson) => lesson._id === selectedLesson)?.name}
                  </span>
                  <CiSquareRemove
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    size={25}
                    onClick={() => onRemoveLesson(selectedLesson)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Button type="submit" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </Form>
      <hr/>
      <hr/>
      <hr/>
      <hr/>
    </Container>
  );
}

export default AddPackage;
