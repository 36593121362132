import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { FaReply } from 'react-icons/fa';
import Alert from 'react-bootstrap/Alert';
// import { HomePage } from '../../translations/trans';
import { useSelector } from 'react-redux';
// import { success } from '../../utils/Toast';
import {  SolarSystemLoading } from 'react-loadingg';
import { sendReportToClass } from '../../../utils/API';
import { success } from '../../../utils/Toast';

const SendReportToClass = ({ classId, }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);  // حالة التحميل
    const [date, setDate] = useState(null);
    const [formTo, setToDate] = useState(null);
    const [message, setMessage] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSendReportToAllClass = async () => {
        setLoading(true);  // ابدأ التحميل
        try {
            const res = await sendReportToClass({
                classId: classId,
                from: date,
                to: formTo
            });
            console.log(res);
            if(res?.data?.case){
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            console.log(e)
            if (e.response) {
                setMessage(e.response.data.message);
            }
        } finally {
            setLoading(false);  // أوقف التحميل
        }
    };

    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
        setDate(formattedDate);
    };

    const handleDateToChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const formattedDate = selectedDate.toISOString().split('.')[0] + '+03:00'; // Format date to the desired format
        setToDate(formattedDate);
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 5000); // Hide the alert after 5 seconds

            return () => clearTimeout(timer); // Cleanup the timer on unmount or if message changes
        }
    }, [message]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <FaReply
                size="16px"
                onClick={handleShow}
                style={{ cursor: 'pointer', color: '#001C45' }}
            />
            {/* Modal Report */}
            <div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Body>
                        {message && (
                            <Alert variant={"danger"}>
                                {message}
                            </Alert>
                        )}
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <label>من:</label>
                                <input type="date" name="fromDate" id="fromDate"
                                    value={date ? date.split('T')[0] : ''}
                                    onChange={handleDateChange}
                                    style={{ border: "none", color: "#120C54", padding: "10px" }} />
                            </div>
                            <div>
                            <label>الي:</label>
                                <input type="date" name="toDate" id="toDate"
                                    value={formTo ? formTo.split('T')[0] : ''}
                                    onChange={handleDateToChange}
                                    style={{ border: "none", color: "#120C54", padding: "10px" }} />
                            </div>
                        </div>

                        <div className="text-center">
                            <button onClick={handleSendReportToAllClass} className='btn' style={{background:"#001C45",padding:"5px",borderRadius:"5px"}}>ارسال</button>
                        </div>
                        {loading && <div className='shadow' style={{ backgroundColor: "white", }}>
                            <SolarSystemLoading	></SolarSystemLoading	>
                        </div>}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

export default SendReportToClass;
