import axios from 'axios';
import { urlBase } from './API';

export const createTest = (data) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/new-test`,
        data: data,
        withCredentials: true
    })
}
export const getTestsByIds = (IDS) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/get-tests-by-ids`,
        data: IDS,//{testIDS:[]},
        withCredentials: true
    })
}
export const getAllTests = () => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/get-all-tests`,
        withCredentials: true
    })
}
export const updateTest = (testObj) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/update-test`,
        withCredentials: true,
        data: testObj
    })
}
// delete test
export const deleteTest = (idObj) => {
    return axios({
        method: 'DELETE',
        url: `${urlBase}/api/delete-test`,
        data: idObj,
        withCredentials: true
    })
}


// check-student-participation-test
export const checkStudentParticipationTest = (data) => {
    return axios({
        method: 'POST',
        url: `${urlBase}/api/check-student-participation-test`,
        data: data,
        // {
        //     "testID": "66d085c39409c05651546841",
        //     "studentID": "66cb971bbb2816dda359e82b"
        // }
        withCredentials: true
    })
}


export const addTestException = (data)=>{
    return axios({
        method: 'POST',
        url: `${urlBase}/api/student-test-exception`,
        data: data,
        // {
        //     "studentID": "66cb971bbb2816dda359e82b",
        //     "testID": "66d085f89409c05651546848",
        //     "exception": 1
        // }
        withCredentials: true
    })
}


