

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Get_All_Packages, Delete_Package } from '../../../actions/packages';
import { Dropdown, DropdownButton, Table, Modal, Button } from 'react-bootstrap';
import { FcDeleteDatabase } from "react-icons/fc";
import Edit from './edit';
import ViewPackage from './viewPackage';
import { HomePage } from '../../../translations/trans';
import EditDetails from './EditDetails';
import ChooseFile from './ChooseFile';
import ModalCoverImage from './ModalCoverImage';

function PackagesList({ handleToggle }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handleDeleteClick = (pack) => {
    setSelectedPackage(pack);
    setShowModal(true); // Show the modal
  };

  const confirmDelete = async () => {
    if (selectedPackage) {
      try {
        await dispatch(Delete_Package({ id: selectedPackage._id }));
        setShowModal(false); // Close the modal after deletion
        setSelectedPackage(null); // Clear the selected package
      } catch (err) {
        console.warn(err.message);
      }
    }
  };

  const getPacks = async () => {
    try {
      await dispatch(Get_All_Packages());
    } catch (err) {
      console.warn(err.message);
    }
  };

  useEffect(() => {
    getPacks();
  }, [dispatch]);

  const { packages } = useSelector(({ packages }) => ({ packages }));

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <>
      <h2>{HomePage.thePrograms[lang]}</h2>
      <Table responsive>
        <thead>
          <tr>
            <th>{HomePage.nameForm[lang]}</th>
            <th>{HomePage.price[lang]}</th>
            <th>{HomePage.edit[lang]}</th>
            <th>{HomePage.edit[lang]}</th>
            <th>{HomePage.delete[lang]}</th>
          </tr>
        </thead>
        <tbody>
          {packages.map((pack) => (
            <tr key={pack._id}>
              <td>{pack.name}</td>
              <td>{pack.price}</td>
              <td>
                <DropdownButton id="dropdown-basic-button" title="اختيارات ">
                  <Dropdown.Item onClick={() => handleToggle(pack)}>
                    <Edit pack={pack} />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <EditDetails pack={pack} />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <ViewPackage pack={pack} />
                  </Dropdown.Item>
                </DropdownButton>
              </td>
              <td>
                <ModalCoverImage pack={pack} />
              </td>

              <td>
                <FcDeleteDatabase
                  onClick={() => handleDeleteClick(pack)} // Open modal for confirmation
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>




      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>حذف الماده</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل انت متاكد من حذف الماده {selectedPackage?.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {HomePage.cancel[lang]}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            تاكيد
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PackagesList;
