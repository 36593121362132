import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { deleteSession } from '../../utils/sessions';
import { ErrorMsg, success } from '../../utils/Toast';
import { removeStudentsFromClass } from '../../utils/class';
const DeleteStudent = ({show, handleClose, Class, studentId, onDeleteSuccess  }) => {

    const handleDelete = async () => {
        try {
            const res = await removeStudentsFromClass({
                id: Class?._id,
                students: [`${studentId}`]
            });
            if(res?.data?.case){
                success(res?.data?.message)
                handleClose();
                onDeleteSuccess(res?.data?.class);
            }
        } catch (e) {
            console.log(e)
            ErrorMsg(`هناك مشكله ما`)
        }
    }
    return (
        <div>
            
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <h5>هل أنت متأكد أنك تريد حذف  هذا الطالب</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        إلغاء
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        حذف
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteStudent
