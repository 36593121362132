import React, { useEffect, useState } from 'react';
import { FaUsers } from "react-icons/fa";
import { Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SendMessage from '../message/sendMessage';
import ChooseReport from './ChooseReport';
import { removeStudentsFromClass } from '../../utils/class';
import { ErrorMsg, success } from '../../utils/Toast';
import { HomePage } from '../../translations/trans';
import DeleteStudent from './DeleteStudent';
import { BsWhatsapp } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import SendWhatsappMsg from './SendWhatsappMsg';
import GetLessonId from '../shared/GetLessonId';
import ModalTestResult from '../Admin/classControl/ModalTestResult';
import Dropdown from 'react-bootstrap/Dropdown';


const ShowUsers = ({ Class }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [lesson, setLesson] = useState(null);
    const [packageData, setPackageData] = useState(null);

    const [selectedStudents, setSelectedStudents] = useState([]); // State to store selected student IDs

    const { students } = useSelector(state => ({
        students: state.users.students,
    }));

    const [classStudents, setClassStudents] = useState([]);

    const fetchStudents = (updatedClass = null) => {
        // const IDS = Class.studentsIDS;
        const IDS = updatedClass ? updatedClass.studentsIDS : Class.studentsIDS;
        const stds = students?.filter(s => IDS.includes(s?._id));
        setClassStudents(stds);
    }

    useEffect(() => {
        fetchStudents();
    }, [Class, students]);

    const { lang } = useSelector(({ language }) => ({
        lang: language.lang,
    }));

    // Function to handle selection of students
    const handleStudentSelect = (studentId) => {
        setSelectedStudents((prevSelected) => {
            if (prevSelected.includes(studentId)) {
                return prevSelected.filter(id => id !== studentId); // Deselect if already selected
            } else {
                return [...prevSelected, studentId]; // Add to selected IDs
            }
        });
    };

    // Function to select all students
    const handleSelectAllStudents = () => {
        if (selectedStudents.length === classStudents.length) {
            // If all are selected, deselect all
            setSelectedStudents([]);
        } else {
            // Otherwise, select all
            const allStudentIds = classStudents.map(student => student._id);
            setSelectedStudents(allStudentIds);
        }
    };

    // Function to remove students to API
    const handleRemoveStudentsFromClasses = async () => {
        try {
            const res = await removeStudentsFromClass({
                id: Class?._id,
                students: selectedStudents
            });
            if (res?.data?.case) {
                success(res?.data?.message);
                fetchStudents(res?.data?.class)
            }
        } catch (e) {
            if (e?.response) {
                ErrorMsg(`هناك مشكله ما`)
            }
        }
    };

    // For Another Modal Open And Close Current Modal

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [whatsApp, setWhatsAppModal] = useState(false);
    const [deleteStudentId, setDeleteStudentId] = useState(null);
    const [student, setStudent] = useState(null);

    const handleDeleteModalClose = () => setShowDeleteModal(false);
    const handleWhatsModalClose = () => setWhatsAppModal(false);

    const handleDeleteModalShow = (studentId) => {
        setDeleteStudentId(studentId);
        setShowDeleteModal(true);
        handleClose();
    };
    const handleWhatsAppModal = (studentId) => {
        setStudent(studentId);
        setWhatsAppModal(true);
        handleClose();
    };


    // For Lesson Classes
    const { packages } = useSelector(({ packages }) => ({
        packages
    }));

    const fetchPackage = async () => {
        const pack = packages.find(p => p._id === Class.packID);
        setPackageData(pack);
    };

    useEffect(() => {
        fetchPackage();
    }, [Class, packages]);

    return (
        <div>
            {/* Modal Student */}
            <FaUsers onClick={handleShow} size={20} color="#001C45" />
            <Modal show={show} onHide={handleClose} size="xl" centered>
                <div className="d-flex justify-content-start">
                    {
                        selectedStudents?.length > 0 ? (
                            <button className="btn w-25 mt-3 mx-2"
                                style={{ background: "#4169E2" }} onClick={handleRemoveStudentsFromClasses}>حذف الطلاب المحددين</button>
                        ) : (
                            <button className="btn w-25 mt-3 mx-2"
                                style={{ background: "#4169E2" }} disabled>حذف الطلاب المحددين</button>
                        )
                    }
                    {/* New Button to Select All Students */}
                    <button className="btn w-25 mt-3 mx-2"
                        style={{ background: "#4169E2" }} onClick={handleSelectAllStudents}>
                        {selectedStudents.length === classStudents.length ? 'إلغاء تحديد الكل' : 'تحديد كل الطلاب'}
                    </button>
                </div>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>حدد</th>
                                <th>{HomePage.nameForm[lang]}</th>
                                <th>{HomePage.email[lang]}</th>
                                <th>{HomePage.sendMessage[lang]}</th>
                                <th>{HomePage.whatsApp[lang]}</th>
                                <th>{HomePage.showResultsLesson[lang]}</th>
                                <th>{HomePage.addreport[lang]}</th>
                                <th>{HomePage.delete[lang]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                classStudents.map((student, index) => (
                                    <tr key={student._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <input
                                                type="checkbox" // Changed to checkbox
                                                name={`selectStudent${index}`}
                                                checked={selectedStudents.includes(student._id)}
                                                onChange={() => handleStudentSelect(student._id)}
                                            />
                                        </td>
                                        <td>{student.name ? student.name : student.username}</td>
                                        <td>{student.email}</td>
                                        <td><SendMessage to={student._id} /></td>
                                        <td>
                                            <BsWhatsapp color={"#25D366"} size={"25"} onClick={() => handleWhatsAppModal(student._id)} />
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {HomePage.lessonName[lang]}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {packageData?.selectedLessons.map((lesson) => (
                                                        <Dropdown.Item key={lesson} value={lesson}>
                                                            <ModalTestResult lessonId={lesson} userId={student?._id} name={student.name} />
                                                        </Dropdown.Item>

                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* <select style={{ border: "none" }} value={lesson} onChange={(e) => setLesson(e.target.value)}>
                                                <option>{HomePage.lessonName[lang]}</option>
                                                {packageData?.selectedLessons.map((lesson) => (
                                                    <option key={lesson} value={lesson}>

                                                        <ModalTestResult lessonId={lesson} />
                                                    </option>
                                                ))}
                                            </select> */}
                                        </td>
                                        <td><ChooseReport classId={Class._id} studentId={student._id} /></td>
                                        <td>
                                            <MdDelete onClick={() => handleDeleteModalShow(student._id)}
                                                style={{ color: "#D43B43" }} size={"25"} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
            <div>
                <DeleteStudent
                    show={showDeleteModal}
                    handleClose={handleDeleteModalClose}
                    Class={Class}
                    studentId={deleteStudentId}
                    onDeleteSuccess={fetchStudents}
                />
            </div>
            <div>
                <SendWhatsappMsg
                    show={whatsApp}
                    handleClose={handleWhatsModalClose}
                    Class={Class}
                    studentId={student}
                />
            </div>
        </div>
    );
};

export default ShowUsers;
