import React from 'react'
import BlueHeader from './blueHeader';
import Contain from '../styled-components/contain.jsx';
import Col from "../styled-components/col.jsx";
import aboutIMG from '../../assets/about.png';
import './about.css';
import abicon1 from '../../assets/about-future.png'
import { NavLink } from 'react-router-dom';
import sec2Ic1 from '../../assets/about1.svg'
import sec2Ic2 from '../../assets/about2.svg'
import sec2Ic3 from '../../assets/about3.svg'
import sec2Ic4 from '../../assets/about4.svg'
import sec3Ic1 from '../../assets/aboutsec31.svg';
import sec3Ic2 from '../../assets/aboutsec32.svg'
import sec3Ic3 from '../../assets/aboutsec33.svg'
import { platformName } from '../../constants.js';
import aboutImage from "../../assets/images/aboutImage.png"
import { PiCertificateFill } from "react-icons/pi";
import { FaSchool } from "react-icons/fa6";
import { PiMagnifyingGlassPlusFill } from "react-icons/pi";
import { useSelector } from 'react-redux';
import { HomePage } from '../../translations/trans.js';

function About() {
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <>
      <BlueHeader title={`${HomePage.aboutUs[lang]}`} subTitle={`${HomePage.home[lang]}`} secondSubTitle={`${HomePage.teachers[lang]}`} />
      <Contain perfix='about-sec1'>
        <Col perfix='absec1-st' justify='center'>
          <div className='row justify-content-between align-items-center mt-5 px-5' dir={dir}>
            <div className='col-lg-6 col-sm-12 col-md-12 col-12'>
              <span className='a-b-s1-sp' id='ab1'>
                {HomePage.saraPlatform[lang]}
              </span>
              <h2 id='ab2'>
                {HomePage.future[lang]}

              </h2>

              <p id='ab3'>
                {HomePage.futureContent[lang]}
              </p>
            </div>
            <div className='col-lg-6 col-sm-12 col-md-12 col-12'>
              <div className='w-50 m-auto'>
                <img src={aboutImage} alt="data" style={{ objectFit: "cover", width: "100%" }} />
              </div>
            </div>
          </div>
        </Col>
        {/* <Col perfix='absec1-en' border='0px'>
        <img  src={aboutIMG} alt='about-Mr-Mohamed-Sonbol'/>
    </Col> */}

      </Contain>
      <Contain perfix='about-sec2' >
        <Col width='80%' perfix='about-sec2-sub-cont'>
          <Col perfix='absec2-2 py-3'>
            <div className='absec2-icons'>
              <div className='absec2st'>
                <div className='absec2-icon'>
                  <img src={sec2Ic1} alt="data" />
                  <h4 id='absec2h4'>
                    {HomePage.lookDevelop[lang]}
                  </h4>
                </div>
                <div className='absec2-icon'>
                  <img src={sec2Ic3} alt="data" />
                  <h4 id='absec2h4'>
                    {HomePage.looktamiz[lang]}
                  </h4>
                </div>
              </div>
              <div className='absec2end'>

                <div className='absec2-icon'>
                  <img src={sec2Ic2} alt="data" />
                  <h4 id='absec2h4'>
                    {HomePage.lookDevelop[lang]}
                  </h4>
                </div>
                <div className='absec2-icon'>
                  <img src={sec2Ic4} alt="data" />
                  <h4 id='absec2h4'>
                    {HomePage.looksuccess[lang]}
                  </h4>
                </div>
              </div>
            </div>
          </Col>
          <Col perfix='absec2-1 px-4'>
            <div>
              <span id='absec2-1'>
                {HomePage.saraPlatform[lang]}
              </span>
              <h3 id='absec2h3'>{HomePage.saraPlatform[lang]}</h3>
              <p id='absec2p' style={{ color: "#6B6B6B" }}>
                {HomePage.whyContent[lang]}
              </p>
              <NavLink id='absec2but' to='/subscription'>
                {HomePage.contactUs[lang]}
              </NavLink>
            </div>
          </Col>
        </Col>

      </Contain>

      {/* Sara Physic */}
      <div className=' shadow p-4 w-75 ' style={{ background: "white", borderRadius: "10px", margin: "30px auto" }}>
        <div className='m-auto text-center '>
          <span id='absec2-1' style={{ color: "#D82839" }}> {HomePage.saraPlatform[lang]}</span>
          <h3 id='absec2h3' style={{ color: "#283476", fontWeight: "800", marginTop: "15px" }}>{HomePage.work[lang]}</h3>
        </div>
        <div className='row mt-5 justify-content-between align-items-center'>
          <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
            <div className="text-center">
              <PiMagnifyingGlassPlusFill size={"35px"} color={"#283476"} />
              <h5 style={{ color: "#283476", marginTop: "15px" }}>{HomePage.workThree[lang]}</h5>
              <p style={{ color: "#06142D" }}>{HomePage.workThreeContent[lang]}</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
            <div className="text-center">
              <FaSchool size={"35px"} color={"#283476"} />
              <h5 style={{ color: "#283476", marginTop: "15px" }}>{HomePage.workTwo[lang]}</h5>
              <p style={{ color: "#06142D" }}>{HomePage.workTwoContent[lang]}</p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
            <div className="text-center">
              <PiCertificateFill size={"35px"} color={"#283476"} />
              <h5 style={{ color: "#283476", marginTop: "15px" }}>{HomePage.workOne[lang]}</h5>
              <p style={{ color: "#06142D" }}>{HomePage.workOneContent[lang]}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <Section3/> */}
    </>
  )
}
function Section3() {
  const icons = [
    { icon: sec3Ic1, title: 'Get excel', dis: 'Create your future!' },
    { icon: sec3Ic2, title: 'Sign up for your course', dis: 'Subscribe to the package and learn everything new' },
    { icon: sec3Ic3, title: 'Find your teacher', dis: 'Find the teacher that is right for you and that you want' },
  ]
  return (
    <section className='absec3-cont'>
      <div className='absec3-sub-cont'>
        <p id='why2'>
          OLP educational platform
        </p>
        <h1 id='absec3h1'>
          How OLP Work?
        </h1>
        <div className='how-works-icons '>
          {icons.map((c, i) => <div className='how-icon-item' key={i}>
            <i><img src={c.icon} /></i>
            <h4 id={'absec3h' + i}>{c.title}</h4>
            <span id={'absec3span' + i}>{c.dis}</span>
          </div>)}
          {/* <img src={v1} width='100%' alt=''/> */}
        </div>
      </div>
    </section>
  )
}

export default About