import React, { useEffect, useState } from 'react';
import AddClass from './addClass';
import { Get_Classes } from '../../../actions/class';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import MenuSelection from '../requests/menuSelection';
import DeactivateClass from './deactivateClass';
import AddStudents from './addStudents';
import SendMessage from '../../message/sendMessage';
import SendMessageToGroup from '../../message/sendMessageToGroup';
import { FaReply } from 'react-icons/fa';
import ClassStudents from './classStudents';
import NewRoom from './newRoom';
import { NavLink } from 'react-router-dom';
import ShowUsers from '../../Teacher/ShowUsers';
import AddMeetingRoom from '../../Teacher/AddMeetingRoom';
import ShowMeetings from '../../Teacher/ShowMeetings';
import { HomePage } from '../../../translations/trans';
import SendReportToClass from './SendReportToClass';
import SendMsgToClass from './SendMsgToClass';

function Classes() {
    const [status, setStatus] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Get_Classes());
    }, [dispatch]);

    const { classes, packages, user } = useSelector(({ classes, packages, loggedUser }) => ({
        classes, packages, user: loggedUser.user
    }));

    const getPackageByID = (id) => {
        const pack = packages.find(p => p._id === id);
        //  console.log('pack',pack)
        return pack
    }

    // Filter classes based on active/inactive status
    const filteredClasses = status ? classes.filter(cls => cls.active) : classes.filter(cls => !cls.active);


    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    // Show Lessons For Class

    
    return (
        <div>
            <AddClass />
            <MenuSelection
                status={status}
                setStatus={setStatus}
                name1={`${HomePage.currentclasses[lang]}`}
                name2={`${HomePage.nonActiveClasses[lang]}`}
            />
            
            <div style={{ fontSize: "14px" }}>
                <Table responsive className='mt-5 text-center'>
                    <thead>
                        <tr>
                            <th>Num</th>
                            <th>{HomePage.class[lang]}</th>
                            <th>{HomePage.studyProgram[lang]}</th>
                            <th>{HomePage.studentLength[lang]}</th>
                            <th>{HomePage.sendMessage[lang]}</th>
                            <th>{HomePage.addStudent[lang]}</th>
                            <th>{HomePage.students[lang]}</th>
                            <th>{HomePage.createRoom[lang]}</th>
                            <th>{HomePage.showMeetings[lang]}</th>
                            <th>{HomePage.sendAll[lang]}</th>
                            <th>{HomePage.sendReports[lang]}</th>
                            <th>{HomePage.deactiveCalss[lang]}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredClasses.map((cls, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{cls.name}</td>
                                <td>{packages && cls.packID && getPackageByID(cls.packID)?.name}</td>
                                <td>{cls.studentsIDS.length}</td>
                                <td><SendMessageToGroup to={cls.studentsIDS} /></td>
                                <td><AddStudents Class={cls} /></td>
                                {/* <td><ClassStudents Class={cls} /></td> */}
                                <td>
                                    <ShowUsers Class={cls} />
                                </td>
                                {/* <td><NewRoom Class={cls} /></td> */}
                                <td>
                                    <AddMeetingRoom packClass={cls} />
                                </td>
                                {/* <td>{cls.roomId ? <NavLink style={{ textDecoration: "none", color: "#120C54" }} to={`/meeting-room/${user._id}/${cls?.roomId}`}>دخول</NavLink> : <span style={{ color: "#120C54" }}>لا يوجد</span>}</td> */}
                                <td style={{ cursor: "pointer" }}>
                                    <ShowMeetings packClass={cls} />
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                    <SendMsgToClass Class={cls}/>
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                    <SendReportToClass classId={cls?._id} />
                                </td>
                                <td> <DeactivateClass classId={cls._id} /></td>
                                {/* <td>
                                <button className='btn' style={{ backgroundColor: "#D43B43" }}>
                                    حذف
                                </button>
                            </td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Classes;
