import axios from "axios"
import {urlBase} from "./API"
// const urlBase = 'http://localhost:8081'
// const urlBase = 'https://test.ag-platform.com'
export const newPr = (data)=>{
      return axios({
        method:'POST',
        data:data,
        withCredentials:true,
        url:`${urlBase}/api/newproduct`
      })
}
export const fetchProducts = ()=>{
      return axios({
        method:'get',
        url:`${urlBase}/api/getproducts`
      })
}
export const deleteproduct = (id)=>{
  return axios({
    method:'post',
    data:{id:id},
    withCredentials:true,
    url:`${urlBase}/api/deleteproduct`
  }) 
} 
export const updateproduct = (id,product)=>{
  return axios({
    method:'post',
    data:{id:id,product:product},
    withCredentials:true,
    url:`${urlBase}/api/updateproduct`
  }) 
}
export const Payment = (payData)=>{ 
  return axios( {
    method: 'post',
    url: `${urlBase}/api/payment/create`,
    data : payData
  }) 
}
// appointment confirmation 
export const Appointment = (data)=>{ 
  return axios({
    method:'post',
    data:data,
    url:`${urlBase}/api/appointment/create`,
    withCredentials:true
  })
}
// edit  /edit/appointment
export const editAppointment = (data)=>{ 
  return axios({
    method:'post',
    data:data,
    url:`${urlBase}/api/edit/appointment`,
    withCredentials:true
  })
}
export const getAppointments = ()=>{
  return axios({
    method:'get',
    url:`${urlBase}/api/get/appointments`,
    responseType: "json",
  })
}