import axios from "axios";
// export const urlBase = 'http://localhost:8080'
export const urlBase = '';
// export const urlBase = 'https://online-couses-platform-production.onrender.com';
// export const urlBase = 'https://ms-sara-back-end.vercel.app';
// export const urlBase = 'https://saraphysics.com';
// export const urlBase = 'https://online-couses-platform-production.onrender.com';

//---- get Adm----
export const getAccounts = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/admins`,
    responseType: "json",
  });
};

// ----------Register--------
export const register = (data) => {
  console.log(data)
  const url = `${urlBase}/api/register/${data.role}`;
  return axios({
    method: "POST",
    data: data,
    withCredentials: true,
    url: url,
  });
};
// Reset Password  request
export const ResetPassRequest = (email) => {
  return axios({
    method: "POST",
    data: email,
    withCredentials: true,
    url: `${urlBase}/api/student/password/reset`
  })
}

// Verify Reset Code
export const verifyCodeRequest = (code) => {
  return axios({
    method: "POST",
    data: code,
    withCredentials: true,
    url: `${urlBase}/api/student/verify/code`
  })
}


//--------check login statue when reload or new session-----------
export const intializeUser = () => {
  return fetch(`${urlBase}/api/auth`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((user) => user)
    .catch((err) => {
      console.log(err);
    });
};

//------- logOut --------
// export const logOut = () => {
//   return fetch(`${urlBase}/api/logout`, {
//     credentials: "include",
//   });
// };
export const logOut = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/logout`,
    withCredentials: true
  })
}
// logOut();
// --------  login------
export const getAuthedUser = (authentcation) => {

  return axios({
    method: "POST",
    data: {
      username: authentcation.phone || authentcation.email,
      phone: typeof parseInt(authentcation.phone) === Number ? true : false,
      password: authentcation.password,
      device: authentcation.device
    },
    withCredentials: true,
    url: `${urlBase}/api/login`,
  }).then((res) => res.data);
};

// get students by ids
export const getStudentsByIDS = (IDS) => {
  return axios({
    method: "POST",
    url: `${urlBase}/api/get-students-by-ids`,
    data: IDS
  })
}

//----- uploading files------

export const uploading = (files) => {
  return axios({
    method: "POST",
    data: files,
    withCredentials: true,
    url: `${urlBase}/api/upload`,
  });
};
//----------Quize End Points
export const quizeRank = (score) => {
  return axios({
    method: "POST",
    data: { score: score },
    withCredentials: true,
    url: `${urlBase}/api/rank`,
  }).then((res) => res.data.rank);
};
export const getQS = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/words`,
    responseType: "json",
  });
};
//------- teachers end point-----
export const getTeachers = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/getteachers`,
  });
};
//-------- set schedual-------
export const setSchedual = (data) => {
  return axios({
    method: "POST",
    data: data,
    withCredentials: true,
    url: `${urlBase}/api/teacher/schedual`,
  });
};
export const update_Teacher = (teacher) => {
  return axios({
    method: "POST",
    data: teacher,
    withCredentials: true,
    url: `${urlBase}/api/teacher/update`,
  });
};
export const delTeacher = (id) => {
  return axios({
    method: "POST",
    data: { id: id },
    withCredentials: true,
    url: `${urlBase}/api/teacher/delete`,
  });
};
//-------- Students end points-----

export const getStudents = () => {
  return axios({
    method: "get",
    // url: `${urlBase}/api/students/get`,
    url: `${urlBase}/api/getteachers`,
    responseType: "json",
  });
};

export const delStudent = (id) => {
  return axios({
    method: "POST",
    data: { id: id },
    withCredentials: true,
    url: `${urlBase}/api/students/delete`,
  });
};

// //----- urlBase----------------------------
export const getUrlbase = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/urlbase`,
  });
};
// send a message
export const sendMessage = (data) => {
  //{message:{title:String,content:String},to:'',sender:''}
  return axios({
    method: "POST",
    data: data, //
    withCredentials: true,
    url: `${urlBase}/api/${data.type}/send`, // type: student or teacher
  });
};
export const sendMessageToGroup = (data) => {
  //{message:{title:String,content:String},to:'',sender:''}
  return axios({
    method: "POST",
    url: `${urlBase}/api/admin/send/group`, // type: student or teacher
    data: data, //
    withCredentials: true,
  });
};
// send message to group

// ----------Translation ---------------

export const saveTranslation = (data) => {
  return axios({
    method: 'post',
    url: `${urlBase}/api/save/translation`,
    data: data//==> {lang:'',text object}
  })
}
export const getTranslation = async () => {

  return axios({
    method: 'get',
    url: `${urlBase}/api/get/translation`,
  }).then(res => {
    return res.data
  })
}

//  levels 
// 1- delete
export const delete_Level = (id) => {
  return axios({
    method: 'delete',
    url: `${urlBase}/api/delete-level`,
    data: id,
    withCredentials: true
  })
}

//2- create

export const create_Levele = (data) => {
  return axios({
    method: 'post',
    url: `${urlBase}/api/new-level`,
    data: data,
    withCredentials: true
  })
}

//  get All levels

export const get_All_Levels = () => {
  return axios({
    method: 'get',
    url: `${urlBase}/api/get-levels`,
    withCredentials: true
  })
}
// update level

export const updateLevel = (level) => {
  console.log(level)
  return axios({
    method: 'post',
    url: `${urlBase}/api/update-level`,
    data: level,
    withCredentials: true,

  })
}
// GET LEVEL BY ID
export const getLevelById = (id) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/get/level/by/id`,
    data: id, // {_id:id}
    withCredentials: true
  })
}

// Send Report
export const sendReport = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/send-class-report`,
    data: data,
    withCredentials: true
  })
}
// Send Exam
export const sendExam = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/send-student-test-report`,
    data: data,
    withCredentials: true
  })
}
// Send All reports
export const sendreportsAll = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/send-all-student-report`,
    data: data,
    withCredentials: true
  })
}


// forget Password By Whats app

export const forgetPassByWhatsapp = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/whatsapp/forget-password`,
    data: data,
    // {phone:0000}
    withCredentials: true
  })
}




// ResetP Pass Afetr request

export const resetPass = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/whatsapp/verify-code`,
    data: data,
    //   {
    //     "phone": "+201022952676",
    //     "newPassword": "memo",
    //     "code": "4d1a9d77"
    // }
    withCredentials: true
  })
}


// Reset Admin PAss

export const resetAdminPass = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/admin/change-password`,
    data: data,
    //   {
    //     "phone": "+201022952676",
    //     "oldPassword": "111",
    //     "newPassword": "memo"
    // }
    withCredentials: true
  })
}

// Reset Class Report To All Class

export const sendReportToClass = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/send-class-report-all-students`,
    data: data,
    //   {
    //     "classId": "66caf5638fd90bc8ad0744b5",
    //     "from": "2024-01-01T00:00:00+03:00", //   // you type YYYY-MM-DDTHH:MM:SS+03:00    You give me date like that  carefull time in 24 format 
    //     "to": "2024-12-01T00:00:00+03:00" //   // you type YYYY-MM-DDTHH:MM:SS+03:00    You give me date like that  carefull time in 24 format 
    // }
    withCredentials: true
  })
}





