import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../utils/lessons';
// import { Container, Button } from 'react-bootstrap';
import "./courseBoard.css"
import "../curriculums/lessons/view.css"
import { useParams } from 'react-router-dom';
import { getSession } from '../../utils/sessions';
import ModalShowSessions from './ModalShowSessions';
import HomeWork from './HomeWork';
import ShowLiveSessions from './ShowLiveSessions';
import { urlBase } from '../../utils/API';
const ViewArea = ({ Lesson }) => {
  // const [pdfNumPages, setPdfNumPages] = useState(null);
  // const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [content, setContent] = useState([]);
  const [sessions, setSessions] = useState([]);



  const { lesson } = useSelector(({ view }) => {
    return { lesson: view?.lesson };
  });

  const getURLS = async () => {
    try {

      // const res = await getContentUrls(!Lesson ? lesson.content : Lesson.content);
      const res = await getContentUrls(lesson.content);
      setContent(res.data.newContent);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getURLS();

  }, [lesson]);

  const params = useParams();
  const getAllSessionForCalss = async () => {
    try {
      const res = await getSession({
        classId: params?.classId
      });
      // console.log(res)
      if (res?.data?.case) {
        setSessions(res?.data?.sessions)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getAllSessionForCalss()
  }, [params?.classId])

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setPdfNumPages(numPages);
  // };

  // const preventRightClick = (e) => {
  //   e.preventDefault();
  // };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <div className='view-area-cont' onContextMenu={preventContextMenu}>
      <div className='d-flex justify-content-between align-items-center w-100 py-3' style={{ borderRadius: "10px", background: "#283476" }}>
        <h2 style={{ color: "white" }}>{lesson ? lesson?.name : Lesson?.name}</h2>
        {
          lesson ? (
            <div className='d-flex'>
              <ModalShowSessions sessions={sessions} lesson={Lesson} />
              <HomeWork sessions={sessions} lesson={Lesson} />
              <ShowLiveSessions sessions={sessions} />
            </div>
          ) : null
        }
      </div>

      {content.length ? (
        <>
          {lesson?.content.map((c, i) => {
            if (c.type.split('/').includes('image')) {
              return (
                <div key={i} onContextMenu={preventContextMenu}>
                  <hr />
                  <img
                    src={`${urlBase}/${c.name}`}  
                    alt="lesson cover"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              );
            } else if (c.type.split('/').includes('video')) {
              return (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
                  <hr />
                  {/* <ReactPlayer url={c.url} controls width="100%" height="auto" /> */}
                  <video controls controlsList="nodownload" width="100%" height="auto">
                    <source src={`${urlBase}/api/files/${c.name}?type=${c?.type}`} type={c.type} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              );
            } else if (c.type.split('/').includes('pdf')) {
              return (
                <div key={i} style={{ marginBottom: '20px', width: '100%' }} onContextMenu={preventContextMenu}>
                  <div className="pdf-container">
                    <iframe style={{ backgroundColor: '#fff' }} src={`${urlBase}/${c.name}`} width="100%" height="1200px" />
                  </div>
                  <div>
                  </div>
                </div>
              );
            }
          })}
        </>
      ) : (
        <>
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        </>
      )}
    </div>
  );
};

export default ViewArea;
